

.content__brand {
  text-align: center !important;
}

.content__info {
  text-align: center !important;
}

.info__container {
  flex-direction: column !important;
}


@media (min-width: 1024px) {
  .footer__content {
    flex-direction: row !important;
    justify-content: start !important;
 
  }

  .footer__content h4 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .content__brand h4 {
    text-align: start !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .content__info a{
    text-align: end !important;
    margin-right: 0 !important;
    margin-left: 30px !important;
  }

  .info__container {
    flex-direction: row !important;
  }

}