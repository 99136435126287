.book-btn {
  width: 40%;
}

@media (min-width: 1024px) {

  .book-btn {
    width: 100%;
  }

  .container__book-btn {
    height: max-content;
  }
}