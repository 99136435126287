@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Teko:wght@300..700&display=swap);
.book-btn {
  width: 40%;
}

@media (min-width: 1024px) {

  .book-btn {
    width: 100%;
  }

  .container__book-btn {
    height: -webkit-max-content;
    height: max-content;
  }
}
.about__logo {
  height: auto;
  width: 50px;
}

@media (min-width: 1024px) {

  .banner-about__image {
    height: auto !important;
  }

  .banner-about__image img {
    height: auto !important;
    max-height: unset !important;
    object-fit: contain !important;
  }

  .about__title {
    text-align: start !important;
    justify-content: start !important;
    padding: 0 16px !important;
  }

  .about__title p {
    font-size: 60px !important;

  }

  .about__description {
    font-size: 25px !important;
  }

  .about__logo {
    height: 70px;
    width: 70px;
  }
}

.landing-banner {
  height: 500px;
}

.banner__content {
  height: -webkit-max-content;
  height: max-content;
}

.content__title {
  font-size: 50px;
}

@media (min-width: 1024px) {
  .landing-banner {
    height: 350px;
  }

  .banner__content {
    height: 100%;
    padding: 35px !important;
  }

  .content__title {
    font-size: 70px;
    font-weight: bolder !important;
  }

  .content__package {
    font-size: 30px !important;
    font-weight: 600;
  }

  .content__package span{
    font-size: 40px !important;
    font-weight: 700;
  }
}
:root {
  --slide-height: 700px;
  --slide-spacing: 1rem;
  --slide-size: 235px;
}

.embla {
  max-width: 48rem;
  margin: auto;
  position: relative;
}

.embla__viewport {
  overflow: hidden;
  position: relative;
}

.embla__container {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  height: 600px;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.coaches__embla__slide__card {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 0;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
}
.embla__controls {
  display: flex;
  position: absolute;
  justify-content: space-between;
  top: 0;
  bottom: 0;
  width: 100%;
}
.embla__buttons {
  padding: 0 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.embla__button {
  display: none;
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--accent);
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;

}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}

.embla__button--prev {
  left: 20px;
}

.embla__button--next {
  right: 35px;
}

.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}
.embla__slide__number {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

@media (min-width: 500px) {
  .embla {
    max-width: 48rem;
    margin: auto;
    position: relative;
    --slide-height: 400px;
    --slide-spacing: 0rem;
    --slide-size: 280px;
  }
}

@media (min-width: 600px) {
  .embla {
    max-width: 100%;
    margin: auto;
    position: relative;
    --slide-height: 480px;
    --slide-spacing: 0rem;
    --slide-size: 340px;
  }
}

@media (min-width: 720px) {
  .embla {
    max-width: 100%;
    margin: auto;
    position: relative;
    --slide-height: 550px;
    --slide-spacing: 0rem;
    --slide-size: 400px;
  }
}

@media (min-width: 1024px) {
  .embla {
    max-width: 1320px;
    margin: auto;
    --slide-height: 30rem;
    --slide-spacing: 1rem;
    --slide-size: 26%;
  }

  .embla__button {
    display: inline-flex;
    display: flex;
  }

  .coaches__header {
    flex-direction: column !important;
    align-items: center;
  }
  .coaches__subtitle {
    max-width: 600px;
    font-size: 15px !important;
  }
}

@media (max-width: 767px) {
  .coaches__embla__slide__card {
    height: 400px;
  }
  .embla__container {
    height: 400px;
  }
}
.package__title {
  font-size: 30px;
}

.card__package {
  height: 225px !important;
  align-items: center;
}

.package__expiration {
  font-size: 10px;
}

.card__header {
  font-size: 10px;
}


@media (min-width: 1024px) {

  .container__card-package {
    max-width: 300px !important;
  }

  .packages__description {
    width: 650px;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .package__title {
    font-size: 40px;
    font-weight:600 !important;
  }

  .card__header {
    font-size: 16px;
  }

  .package__price {
    font-size: 30px !important;
    font-weight:500 !important;
  }

  .package__expiration {
    font-size: 15px !important;
  }

  .package__btn {
    font-size: 24px;
  }
  
}
.schedule-class {
    border-radius: 10px;
    width: 95%;
}

.schedule-col {
    width: calc((100% / 7) - 15px);
}

.time-container {
    min-height: 150px;
}

@media(max-width: 767px) {
    .schedule-col {
        margin-top: 32px;
    }
    .schedule-mobile-container {
        overflow-x: scroll;
    }
    .schedule-mobile-wrapper {
        width: 350%;
    }
}
.schedule__description {
  font-size: 12px;
  max-width: 600px !important;
}

.day__description {
  font-size: 15px;
}

.day__class-description {
  font-size: 20px;
}

.schedule-day__container {
  transition: transform 0.5s, width 0.5s, height 0.5s;
}

.schedule__current-day__date {
  transition: color 0.5s, font-size 0.5s;
}

.schedule-class__active {
  width: 290px;
}

@media (min-width: 1024px) {
  .schedule__description {
    font-size: 15px;
    margin-top: 0px !important;
  }

  .schedule__current-day .day__description {
    font-size: 20px;
  }

  .schedule__current-day .day__class-description {
    font-size: 25px;
  }

  .schedule-class__btn {
    font-size: 20px !important;
  }
  
}
.class-type__content {
  height: 100% !important;
  align-items: center;
}

.content__title {

}

.content__subtitle {
  
}

.content__description {
  margin-top: 0 !important;
}

.content__btn {
  justify-content: end !important;  
}


.class-type__image {
  height: 100% !important;
}

.class-type__image img {
  height: 100% !important;
}

.class-types__img-right, .class-types__img-right .class-type-home {
  height: 450px !important;
}

.class-types__img-left, .class-types__img-left .class-type-home {
  height: 600px !important;
}

.class-types__content-right {
  height: 400px !important;

}

.class-types__content-left {
  height: 350px !important;

}

.reservation-card {
  padding: 14px !important;
}

.reservation-card__content {
  margin: 0 !important;
  align-items: start !important;
}

.reservation-card__content img {
  width: 40px !important;
  height: 40px !important;
}

.content__class-data {
  flex-direction: column !important;
  align-items: start !important;
}

.content__status {
  font-size: 14px !important;
  line-height: 14px;
  padding: 0;
}

.content__cancel-btn {
  font-size: 14px !important;
  line-height: 14px;
}

.class-data__date-time {
  display: flex;
}

.date-time__date {
  font-size: 20px !important;
  width: -webkit-max-content !important;
  width: max-content !important;
  line-height: 20px;
}

.date-time__time {
  font-size: 18px !important;
  width: -webkit-max-content !important;
  width: max-content !important;
  line-height: 18px;
}

.class-data__coach {
  font-size: 16px !important;
  line-height: 16px;
}

@media(min-width: 900px) {
  .reservation-card {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .reservation-card__content {
    align-items: center !important;
  }

  .reservation-card__content img {
    width: 50px !important;
    height: 50px !important;
  }

  .content__class-data {
    flex-direction: row !important;
    align-items: center !important;
  }

  .content__status {
    font-size: 20px !important;
    line-height: 20px;
    padding: 0;
  }

  .class-data__date-time {
    align-items: center;    
  }

  .date-time__date {
    font-size: 32px !important;
    line-height: 32px;
    width: 170px !important;
  }

  .date-time__time {
    font-size: 24px !important;
    line-height: 24px !important;
    height: -webkit-max-content !important;
    height: max-content !important;
    width: 100px !important;
  }

  .class-data__coach {
    font-size: 20px !important;
    line-height: 20px;
  }

  .content__cancel-btn {
    font-size: 20px !important;
    line-height: 20px;
  }
  
}
.profile__current-classes {
  font-size: 20px;
}

.profile__current-package {
  font-size: 20px;
}

.mobile__user-image {
  width: 50px !important;
  height: 50px !important;
}

.profile__mobile-menu {
  transition: height 1s ease-in-out;
}

@media (min-width: 1024px) {
  .profile__current-classes {
    font-size: 24px;
  }
  
  .profile__current-package {
    font-size: 24px;
  }
}



.content__brand {
  text-align: center !important;
}

.content__info {
  text-align: center !important;
}

.info__container {
  flex-direction: column !important;
}


@media (min-width: 1024px) {
  .footer__content {
    flex-direction: row !important;
    justify-content: start !important;
 
  }

  .footer__content h4 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .content__brand h4 {
    text-align: start !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .content__info a{
    text-align: end !important;
    margin-right: 0 !important;
    margin-left: 30px !important;
  }

  .info__container {
    flex-direction: row !important;
  }

}

.navbar__actions {
  padding: 0;
}

.nav-advise {
  margin-bottom: 15px !important;
}


@media (min-width: 1024px) {
  .navbar__actions {
    padding: 0 50px;
  }

  .nav-advise {
    font-size: 15px !important;
    margin-bottom: 15px !important;
  }

  .navbar {
    height: 130px !important;
  }
}
.home-video {
    padding-right: 0px;
    padding-left: 0px;
    z-index: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.plan-card {
    height: 600px;
}

.plan-meta {
    height: 200px;
}

.plan-card .btn-pink {
    position: absolute;
    top: 0px;
    right: 10px;
}

.plan-image {
    top: 0px;
    left: 0px;
    height: 450px;
    object-fit: cover;
    position: absolute;
    border-radius: 15px 15px 0px 0px;
}

.class-type-card {
    height: 300px;
}

.class-type-card-lg {
    height: 650px;
}

.class-type-videos {
    position: absolute;
    right: 10px;
    top: 10px;
}

.class-type-meta {
    height: 75px;
}

.class-type-row * {
    transition: all 0.25s;
}

.class-type-wrapper, .coaches-wrapper {
    max-width: 100vw;
    overflow: hidden;
}

.estrenos-wrapper {
    height: 250px;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.outline-coach-image {
    border-radius: 60px;
    position: absolute;
    height: 335px;
    width: 250px;
    z-index: 0;
    left: 35px;
    top: 30px;
}

.outline-estreno-image {
    border-radius: 20px;
    position: absolute;
    height: 300px;
    width: 200px;
    z-index: 0;
    left: -5px;
    top: 0px;
}

.bg-coach-image {
    border-radius: 60px;
    position: absolute;
    width: 100%;
    z-index: 0;
    left: 70px;
    top: 50px;
}

.bg-estreno-image {
    border-radius: 30px;
    position: absolute;
    height: 300px;
    width: 200px;
    right: 25px;
    top: 25px;
}

.estreno-image {
    height: 100%;
    object-fit: cover;
}

.estreno-card {    
    width: 300px;
    display: flex;
    flex-direction: column;
}

.estreno-card .date-overlay {
    position: absolute;
    top: 50px;
    left: 15px;
    width: 90%;
}

.estreno-card img {
    height: 150px; 
    object-fit: cover;
}

.video-estreno-image {
    border-radius: 20px;
    height: 200px;
    width: 400px;
}

.home-coach-image {
    z-index: 1;
    height: 400;
    margin: auto;
    display: block;    
    object-fit: cover;
}

.estrenos-row {
    transition: all 0.25s;
}

.coaches-row {
    max-height: auto;
}

.class-type-row, .coaches-row {
    transition: all 0.25s;
    overflow-x: visible;
    overflow-y: hidden;
    max-width: 300%;
    max-height: 700px;
    width: 300%;
}

.class-type-column, .coach-column {
    overflow-y: hidden;
    overflow-x: hidden;
    transition: all 0.5s;
    width: 25vw;
}

.class-type-meta, .plan-meta, .program-meta {
    border-radius: 0px 0px 15px 15px;
    justify-content: center;
    flex-direction: column;
    transition: all 0.25s;
    align-items: center;
    position: absolute;    
    display: flex;
    width: 100%;
    bottom: 0px;
    left: 0px;
}

.panel-card {
    height: 500px !important;
    transition: all 0.25s;
}

.program-card {
    height: 500px;
    transition: all 0.25s;
}

.nutrition-tip-image {
    border-radius: 0px 0px 25px 25px;
    position: absolute;
    bottom: 0px;
}

.program-meta { 
    height: 100px;
}

.program-meta .program-title {
    margin-bottom: 0px;
    font-size: 22px;
}

.program-description {
    display: none;
}

.program-card:hover .program-meta {
    height: 200px;
}

.program-card:hover .program-description {
    display: block;
}
.program-card:hover .program-title {
    margin-bottom: 12px;
}

#single-estreno { 
    height: 380px;
}

#hero .tagline {
    font-size: 34px;
}

#about img {
    max-height: 400px;
    object-fit: cover;
    
}

#about #center-image {
    height: 700px;
}

#hero,
.home-video {
    height: 70vh;
    min-height: 70vh;
}


/* To Do: Centrar en todas las pantallas */
#hero #buttons {
    position: absolute;
    width: 75%;
    left: 0;
    bottom: -32px;
    margin-left: 12.5%;
}

#quote #buttons {
    max-width: 1000px;
    margin: auto;
}

@media (max-width: 767px) {
    .display-1 {
        font-size: 2.4rem !important;
    }
    .tagline { 
        font-size: 1.8rem !important;
    }
    #hero #buttons {
        position: relative;
        margin-left: 0px;
        margin-top: 0px;        
        width: 100%;         
    }
    .coach-column {            
        overflow-y: hidden;
        overflow-x: hidden;
        width: 90vw;
    }
    .class-type-row {
        height: 475px;
    }
    .class-type-column {
        width: 75vw;
        height: 500px;
    }
    .class-type-card-lg {
        height: 400px;
    }
    .carousel-control-prev.wide {
        margin-left: -50px;
    }
    .carousel-control-next.wide {
        margin-right: -50px;
    }
    .carousel-control-prev.dark {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    .carousel-control-next.dark {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    .plan-image {
        height: 300px;        
    }
    .plan-card {
        height: 450px;
    }
    .plan-meta {
        padding: 16px;
    }
    .navbar-cta {
        margin-top: 0px;
    }
    nav .collapse.show {
        margin-bottom: 16px;
    }
    .estreno-image {
        height: auto;
        margin-top: 24px;
        object-fit: cover;
    }
    .program-video-card {
        min-height: auto;
    }
    #single-estreno .h-100 {
        height: auto !important;
    }
    #single-estreno {
        height: 500px;
    }
}
:root {
    --primary: #FF0021;
    --primary-light: #F62F34;
    --accent: #FFFFFF;
    --gray: #4B4B4B;
    --light: #707070;
    --dark: #020202;
}

body {
    color: #5C5954;
}

/* Nav */

.nav-item-active {
    color: var(--primary) !important;
}

/* Background */

.bg-primary {
    background-color: var(--primary) !important;
}

.bg-primary-light {
    background-color: var(--primary-light) !important;
}


.bg-accent {
    background-color: var(--accent) !important;
    color: white !important;
}

.bg-gray {
    background-color: var(--gray) !important;
}

.bg-light {
    background-color: var(--light) !important;
}

.bg-dark {
    background-color: var(--dark) !important;
}

.bg-black {
    background-color: #000;
}

.bg-secondary {
    background-color: var(--secondary) !important;
}

.bg-dark-gray {
    background-color: var(--dark-gray) !important;  
}

.bg-opacity {
    background-color: var(--dark-transparency) !important;
}

.bg-special {
    background-color: #C9E881 !important;
}

.bg-accent-secondary {
    background-color: var(--accent-secondary);
}

.bg-secondary-light {
    background-color: var(--secondary-light);
}

.bg-gradient {
    background: linear-gradient(60deg, rgba(157,51,85,1) 0%, rgba(109,134,180,1) 52%, rgba(211,223,237,1) 100%) !important; 
}

/* Text */

.text-primary {
    color: var(--primary) !important;
}

.text-primary-light {
    color: var(--primary-light) !important;
}

.text-accent {
    color: var(--accent) !important;
}

.text-gray {
    color: var(--gray) !important;
}

.text-light {
    color: var(--light) !important;
}

.text-dark {
    color: var(--dark) !important;
}

.text-blue {
    color: var(--blue);
}

.text-secondary {
    color: var(--secondary) !important;
}

.text-black {
    color: black;
}


/* Forms */

.form-control {
    background-color: var(--accent) !important;
}


.border-gray {
    border-color: var(--gray) !important;
}


/* Buttons */

.btn-link {
    color: var(--primary);
}

.btn-online {
    background-color: var(--primary-light);
    border-color: transparent;
    color: var(--primary);
    border-width: 0px;
}

.btn-onsite {
    background-color: var(--secondary-light);
    border-color: transparent;
    color: var(--secondary);
    border-width: 0px;
    
}

.btn-pink {
    background-color: var(--primary-light) !important;
    border: 2px solid var(--primary-light) !important;
    color: white;
}

.btn-warning {
    background-color: var(--warning) !important;
    border: 1px solid var(--warning) !important;
}

.btn-success {
    background-color: var(--success) !important;
    color: black;
}

.btn-outline-primary {
    border: 2px solid var(--primary) !important;
    color: var(--primary) !important;
    font-weight: 700;
}

.btn-outline-primary:hover {
    background-color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    color: white !important;
}

.btn-primary {
    background-color: var(--primary) !important;
    border: 2px solid var(--primary) !important;    
    color: white !important;
    font-weight: 800;
}

.btn-primary:hover {
    color: white !important;
}

.btn-dark {
    background-color: var(--dark) !important;
    border: none;
    color: white !important;
    font-weight: 600;
}

.btn:focus {
    box-shadow: unset;
}

.btn-dark:focus {
    box-shadow: unset;
}

.btn-danger {
    background-color: var(--danger) !important;
}

.btn-sucess {
    background-color: var(--success) !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active {
    background-color: var(--primary-light) !important;
    border: 2px solid var(--primary) !important;    
    font-weight: 800;
}

.btn-secondary {
    border: 2px solid var(--blue) !important;
    background-color: var(--blue) !important;
    color: white;       
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:active {
    background-color: var(--accent);
    border: 2px solid white !important;
    color: white;
    font-weight: 800;
}

.btn-whatsapp {
    background-color: #198754 !important;
    color: white !important;
}

/* Border */

.border-primary {
    border-color: var(--primary) !important;
}

/* Accordion */

.accordion-button:not(.collapsed) {
    color: var(--primary) !important;
    background-color: var(--primary-light) !important;
    
}

.accordion-button:focus {
    box-shadow: 0 0 0 0.25rem var(--primary-light-transparency) !important;
}

/* Misc */

.reply {
    border-left: 5px solid var(--primary);
}

.name-row:focus {
    background-color: var(--light);
}

.black-belt {
    background-color: var(--gray);
}

/* Landing */

.program-meta, .class-type-meta {
    background-color: var(--light-blue);
    color: white;
}

.nutrition-tip-image {
    background-color: var(--light-blue);
}

.program-card:hover .program-meta {
    background-color: var(--primary) !important;
}

.plan-meta {
    background-color: var(--primary);
}

.plan-meta-button {
    background-color: var(--menta) !important;
    border-radius: 150px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    width: 60px;
    height: 60px;
    font-size: 24px;
    position: absolute;
    top: -25px;
    right: 25px;
}

.outline-coach-image {
    border: 1px solid var(--primary) !important;
}

.bg-coach-image {
    background-color: var(--primary) !important;
}

.outline-estreno-image {
    border: 1px solid var(--accent-light) !important;
}

.bg-estreno-image {
    background-color: var(--accent-light) !important;
}

/* Modal */

.modal-content {
    background-color: var(--dark) !important;
    color: white !important;
}

.modal-content div {
    color: white !important;
}

.modal-dialog {
    border: 1px solid #fff;
}

:root {
  --primary: #FF0021;
  --primary-light: #F62F34;
  --accent: #FFFFFF;
  --gray: #4B4B4B;
  --light: #707070;
  --dark: #020202;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Teko", sans-serif;
  font-weight: 800 !important;
  margin-bottom: 20px;
}

button,
.nav-item {
  font-family: "Teko", sans-serif;
}

p,
span,
div {
  font-family: "Raleway", sans-serif;
}

.title-font {
  font-family: "Teko", sans-serif;
}

.description-font {
  font-family: "Raleway", sans-serif;
}

.logo-navbar {
  max-width: 125px;
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--primary) !important;
}

/* .nav-link {
  color: var(--gray);
} */

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 900 !important;
  margin-bottom: 20px;
}

.btn-accent {
  background-color: var(--accent);
  color: var(--gray);
}

.btn-primary {
  background-color: var(--accent);
  border-color: var(--accent);
  font-weight: 800;
  color: #fff;
}

.btn-secondary {
  background-color: #000;
}

body {
  overflow-x: hidden;
  max-width: 100vw;
  height: 100vh;
  width: 100%;
}

label,
div {
  color: var(--dark);
}

.capitalize {
  text-transform: uppercase;
}

.nav-advise {
  font-size: 12px;
}

#offcanvasNavbar {
  z-index: 1500 !important;
}

.row {
  width: 100% ;
  margin: 0 auto;
}

.w-max-content {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.h-max-content {
  height: -webkit-max-content !important;
  height: max-content !important;
}


.btn-favorite {
  border-radius: 70px;
  position: absolute;  
  padding: 6px;  
  height: 35px;
  right: 20px;
  width: 35px;
  z-index: 1;
  top: 10px;
}

.btn-whatsapp {
  position: fixed;
  height: 50px;
  width: 50px;
  font-size: 24px;
  border-radius: 100px;
  bottom: 15px;
  left: 15px;
}

.class-type-home {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-top: 0px;
}

.class-type-title {
  min-height: 100px;
}

.btn-whatsapp-text {
  font-size: 18px;
  top: -10px;
}

#field-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.mw-1000 {
  max-width: 1000px;
}

.hover-grow {
  transition: all 0.25s;
}

.hover-grow:hover {
  transform: scale(1.05);
}

.btn-container {
  width: 103.2% !important;
}

.bg-spinning {
  background-image: url("https://i.blogs.es/a82e3b/istock-1134717663-1-/450_1000.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.nav-container {
  overflow-y: scroll;
  max-height: 75vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.product-img {
  height: 500px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.react-datetime-picker__wrapper {
  border: 0px !important;
}

.panel-mobile-menu {
  display: none;
}

.video-form-thumbnail {
  height: 200px;
  object-fit: cover;
}

.schedule-img {
  margin-top: 32px;
  max-width: 400px;
}

.vh-100 {
  height: 100vh;
}

.paquete-thumbnail {
  max-height: 300px;
  object-fit: cover;
  height: 100%;
}

.card {
  padding: 18px;
}

.card-type {
  max-width: 50px;
}

#fileInput {
  visibility: hidden;
}

.image-input-container {
  background-color: none;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.bmOFCG {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.top-arrow {
  height: 25px;
}

.top-arrow:before {
  content: "";
  position: absolute;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-bottom: 25px solid #f0f0f0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
}

.schedule-class {
  width: 100%;
  float: left;
  cursor: pointer;
  max-width: calc(100% - 15px);
  text-align: center;
  border-radius: 10px;
  border: 1px solid #000;
}

.class-container {
  height: 50px;
  overflow-x: hidden;
}

.text-auto {
  text-transform: none;
}

.vigencia {
  font-size: 18px;
}

.package-card {
  height: 350px;
}

.schedule-class:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-container {
  width: 170%;
}

.latest-badge {
  max-width: 180px;
  position: absolute;
  top: 15px;
  width: 100%;
}

.card.disabled:hover {
  box-shadow: none !important;  
  transform: none;
  cursor: not-allowed;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  transform: scale(1.05);
}

.no-scale:hover {
  transform: none !important;
}

.payment-method {
  max-width: 75px;
}

#paypal-button {
  max-height: 55px;
  overflow: hidden;
}

.color-legend {
  border-radius: 5px;
  height: 25px;
  width: 25px;
}

.user-card {
  position: absolute;
  width: 290px;
  right: 12px;
  transition: all 0.2s;
  position: absolute !important;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
}

.sale-ribbon {
  position: absolute;
  right: 0;
  top: 20px;
}

.strike-through {
  text-decoration: line-through;
}

.transition {
  transition: all 1s;
}

.video-iframe {
  width: 100%;
  height: 75vh;
}

.btn-round {
  border-radius: 80px;
  width: 40px;
  height: 40px;
}

html {
  width: 100%;
  max-width: 100%;
}

.menu-category {
  font-size: 1rem;
  width: 100%;
}

.collapse-nav-item {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none !important;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.collapse-nav-item {
  padding-left: 32px;
}

.collapse-nav-item:hover {
  color: #ababab;
}

.collapsible {
  transition: all 0.1s;
  overflow: hidden;
}

.coach-thumbnail {
  object-fit: cover;
}

.closed {
  height: 0px;
}

.nav-item {
  height: 70px;
  padding-left: 12px;
  padding-right: 12px;
  transition: all 0.25s;
  margin-top: -4px;
  cursor: pointer;
}

.navbar {
  height: 130px;
  padding: 0px;
}

.navbar-cta {
  transition: all 0.25s;
  margin-top: 12px;
}

.program-img {
  max-height: 500px;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

.program-video-card {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.panel-home {
  max-width: 500px;
}

.navbar-cta:hover {
  transform: scale(1.05);
}

.navbar-brand {
  padding-top: 0px;
}

.nav-link {
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  font-weight: 400;
  margin-top: 16px;
}

.text-left {
  text-align: left !important;
}

.side-menu {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  width: 160px;
  background-color: #191919;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 64px;
}

.main-panel {
  background: #fff;
  padding-bottom: 0px;
  min-height: calc(100vh - 70px);
}

.logo-footer {
  max-width: 100px;
}

.h-80 {
  height: 85vh;
  overflow-y: scroll;
}

.modal-lg {
  max-width: 800px;
}

.content {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  min-height: calc(100vh - 65px - 73px);
}

.hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 16px;
}

.schedule-classes-container {
  min-height: 300px;
  height: auto;
  max-width: 300%;
}

.profile-image {
  margin: auto;
  width: 150px;
  height: 150px;
  display: block;
  object-fit: cover;
  border-radius: 300px;
}

.profile-thumbnail {
  border-radius: 100px;
  object-fit: cover;
  height: 60px;
  width: 60px;
}

.logo-thumbnail {
  width: 75px;
}

.icon {
  width: 35px;
  height: 35px;
}

.thumbnail {
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.program-icon {
  width: 125px;
  height: 125px;
  object-fit: cover;
}

.user-icon {
  padding: 20px 22px;
}

.large {
  font-size: 1.2em;
}

.round {
  border-radius: 1000px;
}

.video-thumbnail {
  min-height: 200px;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}

.span-completed {
  padding-left: 10px !important;
  border-radius: 70px;
  position: absolute;  
  padding: 8px;  
  height: 35px;
  right: 50px;
  width: 35px;
  z-index: 1;
  top: 10px;
}

.card-video {
  height: 330px;
  margin-bottom: 32px;
  transition: all 1s;
  padding-bottom: 16px;
}

.card-video-date {
  position: absolute;
  z-index: 1;
  left: 30px;
  top: 15px;
}

.card-video img {
  border-radius: 15px 15px 0px 0px;
}

.card-video p {
  font-size: 14px;
}

.card-video p i {
  font-size: 18px;
}

.card-video h3 {
  font-size: 18px;
}

.panel-title {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
  font-size: 24px;
}

.filter-card {
  padding: 16px;
  margin-bottom: 24px;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.latest-video-bg {
  height: 100%;
  min-height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.latest-card {
  max-height: 320px;
  margin-bottom: 32px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  border-radius: 0px;
}

.latest-card-thumbnail {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-name {
  font-size: 20px;
}

.dropdown-menu {
  margin-left: -45px;
}

.user {
  border-radius: 200px;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.menu-logo {
  max-width: 75px;
}

.side-nav {
  display: block;
  margin-top: 32px;
  list-style-type: none;
  padding-left: 0px;
}

.side-nav li {
  margin-bottom: 16px;
}

.logout-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 16px;
  width: 75%;
}


.logo-navbar {
  max-width: 50px;
}

.logo-login {
  max-width: 100px;
}

.vh-100 {
  height: 100vh;
}

#login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-image {
  object-fit: cover;
  object-position: bottom;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.class-type {
  height: 70vh;
  border-radius: 10px;
  transition: all 0.5s;
}

.select-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.5;
  border-radius: 10px;
}

.type-disabled {
  opacity: 0.5;
}

.class-card {
  background-color: #000;
  z-index: 1;
  border-radius: 10px;
}

.class-type:hover {
  transition: all 0.25s;
  transform: scale(1.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.class-card-wrapper:hover {
  transform: scale(1.01);
  transition: all 0.5s;
}

.select-title {
  color: #fff;
  z-index: 2;
}

.br-0 {
  border-radius: 0px !important;
}

.drawer-menu {
  z-index: 1;
  transition: all 0.25s;
}

.mobile {
  border-radius: 10px;
}

.offcanvas {
  visibility: visible !important;
  z-index: 15 !important;
}

.offcanvas-header {
  margin-top: 16px;
  margin-bottom: 32px;
}

.offcanvas-end {
  width: 50vw;
}

.show {
  display: block;
}

.show-tablet {
  display: none;
}

.landing-logo {
  width: 350px;
}

.landing-container {
  z-index: 1;
}

.landing-row {
  height: 500px;
  position: absolute;
  top: 20vh;
  width: 100%;
}

.landing-button {
  transition: all 0.25s;
}

.landing-button:hover {
  transform: scale(1.1);
}

.bold {
  font-weight: 800;
}

.belt-text {
  z-index: 1;
}

.black-belt {
  height: 250px;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 30%;
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}

.mw-500 {
  max-width: 500px;
}

.mw-800 {
  max-width: 800px;
}

.mw-1400 {
  max-width: 1320px;
}

.mw-1600 {
  max-width: 1500px;
}

.h-auto {
  height: auto;
}

.landing-class-type {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 1;
  filter: brightness(60%);
}

.landing-class-type:after {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.black-overlay {
  background-color: #000;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  opacity: 0.4;
  z-index: 0;
}

.class-type-description {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.checkout-logo {
  max-width: 100px;
}

.class-type-container {
  height: 400px;
  width: 100% !important;
  overflow: hidden;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.br-10 {
  border-radius: 10px;
}

.br-15 {
  border-radius: 10px;
}

.br-25 {
  border-radius: 25px;
}

.landing-class-type-container {
  position: relative;
  overflow: hidden;
  height: 400px;
}
.landing-class-type-container:hover .landing-class-type {
  filter: brightness(20%);
}

.landing-class-type-container:hover .landing-class-type:after {
  opacity: 0.4;
}

.landing-class-type-container:hover .class-type-description {
  opacity: 1;
  color: #fff;
}
.landing-class-type-container:hover .class-type-logo {
  opacity: 0;
}

.class-type-logo {
  position: absolute;
  top: 200px;
  width: 50%;
  height: 50px;
  object-fit: contain;
  left: 25%;
  text-align: center;
}

.bg-vertical {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.show-mobile {
  display: none;
}

.show-mobile-flex {
  display: none;
}

.main-cta-cont {
  z-index: 10;
}

.main-cta-cont h1, .main-cta-cont p, .text-shadow {
  text-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
}

.main-cta-cont p {
  font-size: 24px;
}


.overlay {
  background-color: #000;
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0.75;
  z-index: 1;
}

.loading-text {
  z-index: 10;
}

.loading-logo {
  max-width: 200px;
}

.bg-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.hover-shadow:hover {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.panel-home-item {
  height: 65vh;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
}

td {
  min-width: 125px;
}

.table> :not(caption)>*>* {
  padding: 10px 12px;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom: inherit;
}

th {
  min-width: 200px;
}

.container {
  max-width: 1100px !important;
}

.container-fluid.book-class {
  height: calc(100vh - 225px);
  padding-right: 50px;
  padding-left: 50px;
}

.online-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
}

.main-cta-btn {
  font-size: 20px;
  padding: 10px 16px;
}

.presencial-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}

.schedule-class-cont {
  padding: 15px;
}

.quote {
  position: absolute;
  height: 350px;
  top: -200px;
}

.page-content {
  min-height: 85vh;
}

.planner-calendar-wrapper {  
  margin-left: -12px;
  padding: 12px;  
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 100%;
}

.main__container {
  padding-top: 130px;
}


#loading {
  height: 100vh;
  z-index: 0;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  left: 0;
}

#estrenos { 
  min-height: 400px;
  position: relative;
  overflow: hidden;
}

#workout-planner, #workout-planner .bg-image {
  height: 100vh
}

#planner-calendar .schedule-col {
  margin-top: 0px;
  width: calc(100% / 8);
}

#close-modal {
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 5px;
}

@media (max-width: 1200px) {
  #hero, .home-video {
    height: 100%;
  }
}

@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
}

@media (min-width: 1024px) {
  .main__container {
    padding-top: 130px;
  }
}

@media (max-width: 1000px) {
  #hero {
    height: 80vh;
  }
  .text-left-tablet {
    text-align: left !important;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: inherit;
  }
  .panel-mobile-menu {
    display: block;
  }
  #panel-navbar {
    display: none;
  }
}


@media (min-width: 767px) {

  .resp-calendar {
    display: none;
  }
}

.hide-mobile-flex {
  display: flex;
}

@media (max-width: 767px) {
  #mapa .col {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .container-fluid.book-class {
    padding-right: 0px;
    padding-left: 0px;
    height: auto;
  }
  .page-content {
    min-height: 75vh;
  }
  .logo-thumbnail {
    width: 50px;
  }
  .main-panel {
    min-height: auto;
  }
  .content {
    margin-left: 0px;
    margin-right: 0px;
    min-height: auto;
  }
  .offcanvas-end {
    width: 75vw;
  }
  .latest-video-bg {
    min-height: 180px;
  }
  .latest-card {
    max-height: 100%;
  }
  .schedule-img {
    max-width: 100%;
  }
  .schedule-day {
    margin-bottom: 0px !important;
    width: 100%;
  }
  .mobile-left, .mobile-left.text-end {
    text-align: left !important;
  }
  .btn-reservaciones {
    padding-left: 0px;
  }
  .schedule {
    margin-top: 12px;
  }
  .schedule-class-cont {
    border: 0px !important;
    box-shadow: none !important;
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
  }
  .main-cta-btn {
    font-size: 16px;
    padding: 8px 12px;
  }
  .main-cta-cont p {
    font-size: 18px;
  }
  .show-mobile {
    display: inherit;
  }
  .show-mobile-flex {
    display: flex;
  }
  .hide-mobile {
    display: none;
  }
  .hide-mobile-flex {
    display: none;
  }
  .side-menu {
    display: none;
  }
  .text-funny {
    font-size: 32px;
  }
  .user-card {
    display: none;
  }
  .nav-item {
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav-link {
    margin-top: 0px !important;
  }
  .mobile {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .schedule-container {
    width: 100%;
  }
  .h-80 {
    height: auto;
  }
  p.badge {
    font-size: 1.2rem !important;
  }
  .navbar-container {
    padding-right: 24px;
    padding-top: 0px;
  }
  .navbar {
    max-width: 100vw !important;
    overflow: hidden;
  }
  .mobile-center, .mobile-center * {
    text-align: center !important;
  }
  #main-cta {
    position: relative;
    top: auto;
    left: auto;
  }
  #hero {
    /* background-image: url("/src/assets/images/banner_movil.jpeg"); */
    height: calc(100vh - 130px);
  }
  #estrenos {
    height: auto;
  }
  #workout-planner {
    height: auto;
    min-height: 80vh;
  }
  #workout-planner .bg-image {
    height: 40vh;
  }
  #planner-calendar {
    min-width: 800px;
  }
  #planner-section {
    position: relative;
  }
  #planner-section .arrow-left {
    position: absolute;
    left: -30px;
    top: 25%;
  }
  #planner-section .arrow-right {
    position: absolute;
    right: -30px;
    top: 25%;
  }
}

