.reservation-card {
  padding: 14px !important;
}

.reservation-card__content {
  margin: 0 !important;
  align-items: start !important;
}

.reservation-card__content img {
  width: 40px !important;
  height: 40px !important;
}

.content__class-data {
  flex-direction: column !important;
  align-items: start !important;
}

.content__status {
  font-size: 14px !important;
  line-height: 14px;
  padding: 0;
}

.content__cancel-btn {
  font-size: 14px !important;
  line-height: 14px;
}

.class-data__date-time {
  display: flex;
}

.date-time__date {
  font-size: 20px !important;
  width: max-content !important;
  line-height: 20px;
}

.date-time__time {
  font-size: 18px !important;
  width: max-content !important;
  line-height: 18px;
}

.class-data__coach {
  font-size: 16px !important;
  line-height: 16px;
}

@media(min-width: 900px) {
  .reservation-card {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .reservation-card__content {
    align-items: center !important;
  }

  .reservation-card__content img {
    width: 50px !important;
    height: 50px !important;
  }

  .content__class-data {
    flex-direction: row !important;
    align-items: center !important;
  }

  .content__status {
    font-size: 20px !important;
    line-height: 20px;
    padding: 0;
  }

  .class-data__date-time {
    align-items: center;    
  }

  .date-time__date {
    font-size: 32px !important;
    line-height: 32px;
    width: 170px !important;
  }

  .date-time__time {
    font-size: 24px !important;
    line-height: 24px !important;
    height: max-content !important;
    width: 100px !important;
  }

  .class-data__coach {
    font-size: 20px !important;
    line-height: 20px;
  }

  .content__cancel-btn {
    font-size: 20px !important;
    line-height: 20px;
  }
  
}