.schedule__description {
  font-size: 12px;
  max-width: 600px !important;
}

.day__description {
  font-size: 15px;
}

.day__class-description {
  font-size: 20px;
}

.schedule-day__container {
  transition: transform 0.5s, width 0.5s, height 0.5s;
}

.schedule__current-day__date {
  transition: color 0.5s, font-size 0.5s;
}

.schedule-class__active {
  width: 290px;
}

@media (min-width: 1024px) {
  .schedule__description {
    font-size: 15px;
    margin-top: 0px !important;
  }

  .schedule__current-day .day__description {
    font-size: 20px;
  }

  .schedule__current-day .day__class-description {
    font-size: 25px;
  }

  .schedule-class__btn {
    font-size: 20px !important;
  }
  
}