:root {
    --primary: #FF0021;
    --primary-light: #F62F34;
    --accent: #FFFFFF;
    --gray: #4B4B4B;
    --light: #707070;
    --dark: #020202;
}

body {
    color: #5C5954;
}

/* Nav */

.nav-item-active {
    color: var(--primary) !important;
}

/* Background */

.bg-primary {
    background-color: var(--primary) !important;
}

.bg-primary-light {
    background-color: var(--primary-light) !important;
}


.bg-accent {
    background-color: var(--accent) !important;
    color: white !important;
}

.bg-gray {
    background-color: var(--gray) !important;
}

.bg-light {
    background-color: var(--light) !important;
}

.bg-dark {
    background-color: var(--dark) !important;
}

.bg-black {
    background-color: #000;
}

.bg-secondary {
    background-color: var(--secondary) !important;
}

.bg-dark-gray {
    background-color: var(--dark-gray) !important;  
}

.bg-opacity {
    background-color: var(--dark-transparency) !important;
}

.bg-special {
    background-color: #C9E881 !important;
}

.bg-accent-secondary {
    background-color: var(--accent-secondary);
}

.bg-secondary-light {
    background-color: var(--secondary-light);
}

.bg-gradient {
    background: linear-gradient(60deg, rgba(157,51,85,1) 0%, rgba(109,134,180,1) 52%, rgba(211,223,237,1) 100%) !important; 
}

/* Text */

.text-primary {
    color: var(--primary) !important;
}

.text-primary-light {
    color: var(--primary-light) !important;
}

.text-accent {
    color: var(--accent) !important;
}

.text-gray {
    color: var(--gray) !important;
}

.text-light {
    color: var(--light) !important;
}

.text-dark {
    color: var(--dark) !important;
}

.text-blue {
    color: var(--blue);
}

.text-secondary {
    color: var(--secondary) !important;
}

.text-black {
    color: black;
}


/* Forms */

.form-control {
    background-color: var(--accent) !important;
}


.border-gray {
    border-color: var(--gray) !important;
}


/* Buttons */

.btn-link {
    color: var(--primary);
}

.btn-online {
    background-color: var(--primary-light);
    border-color: transparent;
    color: var(--primary);
    border-width: 0px;
}

.btn-onsite {
    background-color: var(--secondary-light);
    border-color: transparent;
    color: var(--secondary);
    border-width: 0px;
    
}

.btn-pink {
    background-color: var(--primary-light) !important;
    border: 2px solid var(--primary-light) !important;
    color: white;
}

.btn-warning {
    background-color: var(--warning) !important;
    border: 1px solid var(--warning) !important;
}

.btn-success {
    background-color: var(--success) !important;
    color: black;
}

.btn-outline-primary {
    border: 2px solid var(--primary) !important;
    color: var(--primary) !important;
    font-weight: 700;
}

.btn-outline-primary:hover {
    background-color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    color: white !important;
}

.btn-primary {
    background-color: var(--primary) !important;
    border: 2px solid var(--primary) !important;    
    color: white !important;
    font-weight: 800;
}

.btn-primary:hover {
    color: white !important;
}

.btn-dark {
    background-color: var(--dark) !important;
    border: none;
    color: white !important;
    font-weight: 600;
}

.btn:focus {
    box-shadow: unset;
}

.btn-dark:focus {
    box-shadow: unset;
}

.btn-danger {
    background-color: var(--danger) !important;
}

.btn-sucess {
    background-color: var(--success) !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active {
    background-color: var(--primary-light) !important;
    border: 2px solid var(--primary) !important;    
    font-weight: 800;
}

.btn-secondary {
    border: 2px solid var(--blue) !important;
    background-color: var(--blue) !important;
    color: white;       
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:active {
    background-color: var(--accent);
    border: 2px solid white !important;
    color: white;
    font-weight: 800;
}

.btn-whatsapp {
    background-color: #198754 !important;
    color: white !important;
}

/* Border */

.border-primary {
    border-color: var(--primary) !important;
}

/* Accordion */

.accordion-button:not(.collapsed) {
    color: var(--primary) !important;
    background-color: var(--primary-light) !important;
    
}

.accordion-button:focus {
    box-shadow: 0 0 0 0.25rem var(--primary-light-transparency) !important;
}

/* Misc */

.reply {
    border-left: 5px solid var(--primary);
}

.name-row:focus {
    background-color: var(--light);
}

.black-belt {
    background-color: var(--gray);
}

/* Landing */

.program-meta, .class-type-meta {
    background-color: var(--light-blue);
    color: white;
}

.nutrition-tip-image {
    background-color: var(--light-blue);
}

.program-card:hover .program-meta {
    background-color: var(--primary) !important;
}

.plan-meta {
    background-color: var(--primary);
}

.plan-meta-button {
    background-color: var(--menta) !important;
    border-radius: 150px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    width: 60px;
    height: 60px;
    font-size: 24px;
    position: absolute;
    top: -25px;
    right: 25px;
}

.outline-coach-image {
    border: 1px solid var(--primary) !important;
}

.bg-coach-image {
    background-color: var(--primary) !important;
}

.outline-estreno-image {
    border: 1px solid var(--accent-light) !important;
}

.bg-estreno-image {
    background-color: var(--accent-light) !important;
}

/* Modal */

.modal-content {
    background-color: var(--dark) !important;
    color: white !important;
}

.modal-content div {
    color: white !important;
}

.modal-dialog {
    border: 1px solid #fff;
}