
.navbar__actions {
  padding: 0;
}

.nav-advise {
  margin-bottom: 15px !important;
}


@media (min-width: 1024px) {
  .navbar__actions {
    padding: 0 50px;
  }

  .nav-advise {
    font-size: 15px !important;
    margin-bottom: 15px !important;
  }

  .navbar {
    height: 130px !important;
  }
}