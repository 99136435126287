.package__title {
  font-size: 30px;
}

.card__package {
  height: 225px !important;
  align-items: center;
}

.package__expiration {
  font-size: 10px;
}

.card__header {
  font-size: 10px;
}


@media (min-width: 1024px) {

  .container__card-package {
    max-width: 300px !important;
  }

  .packages__description {
    width: 650px;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .package__title {
    font-size: 40px;
    font-weight:600 !important;
  }

  .card__header {
    font-size: 16px;
  }

  .package__price {
    font-size: 30px !important;
    font-weight:500 !important;
  }

  .package__expiration {
    font-size: 15px !important;
  }

  .package__btn {
    font-size: 24px;
  }
  
}