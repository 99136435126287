body {
  overflow-x: hidden;
  max-width: 100vw;
  height: 100vh;
  width: 100%;
}

label,
div {
  color: var(--dark);
}

.capitalize {
  text-transform: uppercase;
}

.nav-advise {
  font-size: 12px;
}

#offcanvasNavbar {
  z-index: 1500 !important;
}

.row {
  width: 100% ;
  margin: 0 auto;
}

.w-max-content {
  width: max-content !important;
}

.h-max-content {
  height: max-content !important;
}


.btn-favorite {
  border-radius: 70px;
  position: absolute;  
  padding: 6px;  
  height: 35px;
  right: 20px;
  width: 35px;
  z-index: 1;
  top: 10px;
}

.btn-whatsapp {
  position: fixed;
  height: 50px;
  width: 50px;
  font-size: 24px;
  border-radius: 100px;
  bottom: 15px;
  left: 15px;
}

.class-type-home {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-top: 0px;
}

.class-type-title {
  min-height: 100px;
}

.btn-whatsapp-text {
  font-size: 18px;
  top: -10px;
}

#field-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.mw-1000 {
  max-width: 1000px;
}

.hover-grow {
  transition: all 0.25s;
}

.hover-grow:hover {
  transform: scale(1.05);
}

.btn-container {
  width: 103.2% !important;
}

.bg-spinning {
  background-image: url("https://i.blogs.es/a82e3b/istock-1134717663-1-/450_1000.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.nav-container {
  overflow-y: scroll;
  max-height: 75vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.product-img {
  height: 500px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.react-datetime-picker__wrapper {
  border: 0px !important;
}

.panel-mobile-menu {
  display: none;
}

.video-form-thumbnail {
  height: 200px;
  object-fit: cover;
}

.schedule-img {
  margin-top: 32px;
  max-width: 400px;
}

.vh-100 {
  height: 100vh;
}

.paquete-thumbnail {
  max-height: 300px;
  object-fit: cover;
  height: 100%;
}

.card {
  padding: 18px;
}

.card-type {
  max-width: 50px;
}

#fileInput {
  visibility: hidden;
}

.image-input-container {
  background-color: none;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.bmOFCG {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.top-arrow {
  height: 25px;
}

.top-arrow:before {
  content: "";
  position: absolute;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-bottom: 25px solid #f0f0f0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
}

.schedule-class {
  width: 100%;
  float: left;
  cursor: pointer;
  max-width: calc(100% - 15px);
  text-align: center;
  border-radius: 10px;
  border: 1px solid #000;
}

.class-container {
  height: 50px;
  overflow-x: hidden;
}

.text-auto {
  text-transform: none;
}

.vigencia {
  font-size: 18px;
}

.package-card {
  height: 350px;
}

.schedule-class:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-container {
  width: 170%;
}

.latest-badge {
  max-width: 180px;
  position: absolute;
  top: 15px;
  width: 100%;
}

.card.disabled:hover {
  box-shadow: none !important;  
  transform: none;
  cursor: not-allowed;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  transform: scale(1.05);
}

.no-scale:hover {
  transform: none !important;
}

.payment-method {
  max-width: 75px;
}

#paypal-button {
  max-height: 55px;
  overflow: hidden;
}

.color-legend {
  border-radius: 5px;
  height: 25px;
  width: 25px;
}

.user-card {
  position: absolute;
  width: 290px;
  right: 12px;
  transition: all 0.2s;
  position: absolute !important;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
}

.sale-ribbon {
  position: absolute;
  right: 0;
  top: 20px;
}

.strike-through {
  text-decoration: line-through;
}

.transition {
  transition: all 1s;
}

.video-iframe {
  width: 100%;
  height: 75vh;
}

.btn-round {
  border-radius: 80px;
  width: 40px;
  height: 40px;
}

html {
  width: 100%;
  max-width: 100%;
}

.menu-category {
  font-size: 1rem;
  width: 100%;
}

.collapse-nav-item {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none !important;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.collapse-nav-item {
  padding-left: 32px;
}

.collapse-nav-item:hover {
  color: #ababab;
}

.collapsible {
  transition: all 0.1s;
  overflow: hidden;
}

.coach-thumbnail {
  object-fit: cover;
}

.closed {
  height: 0px;
}

.nav-item {
  height: 70px;
  padding-left: 12px;
  padding-right: 12px;
  transition: all 0.25s;
  margin-top: -4px;
  cursor: pointer;
}

.navbar {
  height: 130px;
  padding: 0px;
}

.navbar-cta {
  transition: all 0.25s;
  margin-top: 12px;
}

.program-img {
  max-height: 500px;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

.program-video-card {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.panel-home {
  max-width: 500px;
}

.navbar-cta:hover {
  transform: scale(1.05);
}

.navbar-brand {
  padding-top: 0px;
}

.nav-link {
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  font-weight: 400;
  margin-top: 16px;
}

.text-left {
  text-align: left !important;
}

.side-menu {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  width: 160px;
  background-color: #191919;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 64px;
}

.main-panel {
  background: #fff;
  padding-bottom: 0px;
  min-height: calc(100vh - 70px);
}

.logo-footer {
  max-width: 100px;
}

.h-80 {
  height: 85vh;
  overflow-y: scroll;
}

.modal-lg {
  max-width: 800px;
}

.content {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  min-height: calc(100vh - 65px - 73px);
}

.hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 16px;
}

.schedule-classes-container {
  min-height: 300px;
  height: auto;
  max-width: 300%;
}

.profile-image {
  margin: auto;
  width: 150px;
  height: 150px;
  display: block;
  object-fit: cover;
  border-radius: 300px;
}

.profile-thumbnail {
  border-radius: 100px;
  object-fit: cover;
  height: 60px;
  width: 60px;
}

.logo-thumbnail {
  width: 75px;
}

.icon {
  width: 35px;
  height: 35px;
}

.thumbnail {
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.program-icon {
  width: 125px;
  height: 125px;
  object-fit: cover;
}

.user-icon {
  padding: 20px 22px;
}

.large {
  font-size: 1.2em;
}

.round {
  border-radius: 1000px;
}

.video-thumbnail {
  min-height: 200px;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}

.span-completed {
  padding-left: 10px !important;
  border-radius: 70px;
  position: absolute;  
  padding: 8px;  
  height: 35px;
  right: 50px;
  width: 35px;
  z-index: 1;
  top: 10px;
}

.card-video {
  height: 330px;
  margin-bottom: 32px;
  transition: all 1s;
  padding-bottom: 16px;
}

.card-video-date {
  position: absolute;
  z-index: 1;
  left: 30px;
  top: 15px;
}

.card-video img {
  border-radius: 15px 15px 0px 0px;
}

.card-video p {
  font-size: 14px;
}

.card-video p i {
  font-size: 18px;
}

.card-video h3 {
  font-size: 18px;
}

.panel-title {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
  font-size: 24px;
}

.filter-card {
  padding: 16px;
  margin-bottom: 24px;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.latest-video-bg {
  height: 100%;
  min-height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.latest-card {
  max-height: 320px;
  margin-bottom: 32px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  border-radius: 0px;
}

.latest-card-thumbnail {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-name {
  font-size: 20px;
}

.dropdown-menu {
  margin-left: -45px;
}

.user {
  border-radius: 200px;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.menu-logo {
  max-width: 75px;
}

.side-nav {
  display: block;
  margin-top: 32px;
  list-style-type: none;
  padding-left: 0px;
}

.side-nav li {
  margin-bottom: 16px;
}

.logout-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 16px;
  width: 75%;
}


.logo-navbar {
  max-width: 50px;
}

.logo-login {
  max-width: 100px;
}

.vh-100 {
  height: 100vh;
}

#login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-image {
  object-fit: cover;
  object-position: bottom;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.class-type {
  height: 70vh;
  border-radius: 10px;
  transition: all 0.5s;
}

.select-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.5;
  border-radius: 10px;
}

.type-disabled {
  opacity: 0.5;
}

.class-card {
  background-color: #000;
  z-index: 1;
  border-radius: 10px;
}

.class-type:hover {
  transition: all 0.25s;
  transform: scale(1.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.class-card-wrapper:hover {
  transform: scale(1.01);
  transition: all 0.5s;
}

.select-title {
  color: #fff;
  z-index: 2;
}

.br-0 {
  border-radius: 0px !important;
}

.drawer-menu {
  z-index: 1;
  transition: all 0.25s;
}

.mobile {
  border-radius: 10px;
}

.offcanvas {
  visibility: visible !important;
  z-index: 15 !important;
}

.offcanvas-header {
  margin-top: 16px;
  margin-bottom: 32px;
}

.offcanvas-end {
  width: 50vw;
}

.show {
  display: block;
}

.show-tablet {
  display: none;
}

.landing-logo {
  width: 350px;
}

.landing-container {
  z-index: 1;
}

.landing-row {
  height: 500px;
  position: absolute;
  top: 20vh;
  width: 100%;
}

.landing-button {
  transition: all 0.25s;
}

.landing-button:hover {
  transform: scale(1.1);
}

.bold {
  font-weight: 800;
}

.belt-text {
  z-index: 1;
}

.black-belt {
  height: 250px;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 30%;
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}

.mw-500 {
  max-width: 500px;
}

.mw-800 {
  max-width: 800px;
}

.mw-1400 {
  max-width: 1320px;
}

.mw-1600 {
  max-width: 1500px;
}

.h-auto {
  height: auto;
}

.landing-class-type {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  transition: 0.5s ease;
  backface-visibility: hidden;
  z-index: 1;
  filter: brightness(60%);
}

.landing-class-type:after {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.black-overlay {
  background-color: #000;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  opacity: 0.4;
  z-index: 0;
}

.class-type-description {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.checkout-logo {
  max-width: 100px;
}

.class-type-container {
  height: 400px;
  width: 100% !important;
  overflow: hidden;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.br-10 {
  border-radius: 10px;
}

.br-15 {
  border-radius: 10px;
}

.br-25 {
  border-radius: 25px;
}

.landing-class-type-container {
  position: relative;
  overflow: hidden;
  height: 400px;
}
.landing-class-type-container:hover .landing-class-type {
  filter: brightness(20%);
}

.landing-class-type-container:hover .landing-class-type:after {
  opacity: 0.4;
}

.landing-class-type-container:hover .class-type-description {
  opacity: 1;
  color: #fff;
}
.landing-class-type-container:hover .class-type-logo {
  opacity: 0;
}

.class-type-logo {
  position: absolute;
  top: 200px;
  width: 50%;
  height: 50px;
  object-fit: contain;
  left: 25%;
  text-align: center;
}

.bg-vertical {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.show-mobile {
  display: none;
}

.show-mobile-flex {
  display: none;
}

.main-cta-cont {
  z-index: 10;
}

.main-cta-cont h1, .main-cta-cont p, .text-shadow {
  text-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
}

.main-cta-cont p {
  font-size: 24px;
}


.overlay {
  background-color: #000;
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0.75;
  z-index: 1;
}

.loading-text {
  z-index: 10;
}

.loading-logo {
  max-width: 200px;
}

.bg-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.hover-shadow:hover {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.panel-home-item {
  height: 65vh;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
}

td {
  min-width: 125px;
}

.table> :not(caption)>*>* {
  padding: 10px 12px;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom: inherit;
}

th {
  min-width: 200px;
}

.container {
  max-width: 1100px !important;
}

.container-fluid.book-class {
  height: calc(100vh - 225px);
  padding-right: 50px;
  padding-left: 50px;
}

.online-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
}

.main-cta-btn {
  font-size: 20px;
  padding: 10px 16px;
}

.presencial-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}

.schedule-class-cont {
  padding: 15px;
}

.quote {
  position: absolute;
  height: 350px;
  top: -200px;
}

.page-content {
  min-height: 85vh;
}

.planner-calendar-wrapper {  
  margin-left: -12px;
  padding: 12px;  
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 100%;
}

.main__container {
  padding-top: 130px;
}


#loading {
  height: 100vh;
  z-index: 0;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  left: 0;
}

#estrenos { 
  min-height: 400px;
  position: relative;
  overflow: hidden;
}

#workout-planner, #workout-planner .bg-image {
  height: 100vh
}

#planner-calendar .schedule-col {
  margin-top: 0px;
  width: calc(100% / 8);
}

#close-modal {
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 5px;
}

@media (max-width: 1200px) {
  #hero, .home-video {
    height: 100%;
  }
}

@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
}

@media (min-width: 1024px) {
  .main__container {
    padding-top: 130px;
  }
}

@media (max-width: 1000px) {
  #hero {
    height: 80vh;
  }
  .text-left-tablet {
    text-align: left !important;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: inherit;
  }
  .panel-mobile-menu {
    display: block;
  }
  #panel-navbar {
    display: none;
  }
}


@media (min-width: 767px) {

  .resp-calendar {
    display: none;
  }
}

.hide-mobile-flex {
  display: flex;
}

@media (max-width: 767px) {
  #mapa .col {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .container-fluid.book-class {
    padding-right: 0px;
    padding-left: 0px;
    height: auto;
  }
  .page-content {
    min-height: 75vh;
  }
  .logo-thumbnail {
    width: 50px;
  }
  .main-panel {
    min-height: auto;
  }
  .content {
    margin-left: 0px;
    margin-right: 0px;
    min-height: auto;
  }
  .offcanvas-end {
    width: 75vw;
  }
  .latest-video-bg {
    min-height: 180px;
  }
  .latest-card {
    max-height: 100%;
  }
  .schedule-img {
    max-width: 100%;
  }
  .schedule-day {
    margin-bottom: 0px !important;
    width: 100%;
  }
  .mobile-left, .mobile-left.text-end {
    text-align: left !important;
  }
  .btn-reservaciones {
    padding-left: 0px;
  }
  .schedule {
    margin-top: 12px;
  }
  .schedule-class-cont {
    border: 0px !important;
    box-shadow: none !important;
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
  }
  .main-cta-btn {
    font-size: 16px;
    padding: 8px 12px;
  }
  .main-cta-cont p {
    font-size: 18px;
  }
  .show-mobile {
    display: inherit;
  }
  .show-mobile-flex {
    display: flex;
  }
  .hide-mobile {
    display: none;
  }
  .hide-mobile-flex {
    display: none;
  }
  .side-menu {
    display: none;
  }
  .text-funny {
    font-size: 32px;
  }
  .user-card {
    display: none;
  }
  .nav-item {
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav-link {
    margin-top: 0px !important;
  }
  .mobile {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .schedule-container {
    width: 100%;
  }
  .h-80 {
    height: auto;
  }
  p.badge {
    font-size: 1.2rem !important;
  }
  .navbar-container {
    padding-right: 24px;
    padding-top: 0px;
  }
  .navbar {
    max-width: 100vw !important;
    overflow: hidden;
  }
  .mobile-center, .mobile-center * {
    text-align: center !important;
  }
  #main-cta {
    position: relative;
    top: auto;
    left: auto;
  }
  #hero {
    /* background-image: url("/src/assets/images/banner_movil.jpeg"); */
    height: calc(100vh - 130px);
  }
  #estrenos {
    height: auto;
  }
  #workout-planner {
    height: auto;
    min-height: 80vh;
  }
  #workout-planner .bg-image {
    height: 40vh;
  }
  #planner-calendar {
    min-width: 800px;
  }
  #planner-section {
    position: relative;
  }
  #planner-section .arrow-left {
    position: absolute;
    left: -30px;
    top: 25%;
  }
  #planner-section .arrow-right {
    position: absolute;
    right: -30px;
    top: 25%;
  }
}
