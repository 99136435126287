.class-type__content {
  height: 100% !important;
  align-items: center;
}

.content__title {

}

.content__subtitle {
  
}

.content__description {
  margin-top: 0 !important;
}

.content__btn {
  justify-content: end !important;  
}


.class-type__image {
  height: 100% !important;
}

.class-type__image img {
  height: 100% !important;
}

.class-types__img-right, .class-types__img-right .class-type-home {
  height: 450px !important;
}

.class-types__img-left, .class-types__img-left .class-type-home {
  height: 600px !important;
}

.class-types__content-right {
  height: 400px !important;

}

.class-types__content-left {
  height: 350px !important;

}