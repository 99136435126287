
.landing-banner {
  height: 500px;
}

.banner__content {
  height: max-content;
}

.content__title {
  font-size: 50px;
}

@media (min-width: 1024px) {
  .landing-banner {
    height: 350px;
  }

  .banner__content {
    height: 100%;
    padding: 35px !important;
  }

  .content__title {
    font-size: 70px;
    font-weight: bolder !important;
  }

  .content__package {
    font-size: 30px !important;
    font-weight: 600;
  }

  .content__package span{
    font-size: 40px !important;
    font-weight: 700;
  }
}