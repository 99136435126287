@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Teko:wght@300..700&display=swap');

:root {
  --primary: #FF0021;
  --primary-light: #F62F34;
  --accent: #FFFFFF;
  --gray: #4B4B4B;
  --light: #707070;
  --dark: #020202;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Teko", sans-serif;
  font-weight: 800 !important;
  margin-bottom: 20px;
}

button,
.nav-item {
  font-family: "Teko", sans-serif;
}

p,
span,
div {
  font-family: "Raleway", sans-serif;
}

.title-font {
  font-family: "Teko", sans-serif;
}

.description-font {
  font-family: "Raleway", sans-serif;
}

.logo-navbar {
  max-width: 125px;
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--primary) !important;
}

/* .nav-link {
  color: var(--gray);
} */

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 900 !important;
  margin-bottom: 20px;
}

.btn-accent {
  background-color: var(--accent);
  color: var(--gray);
}

.btn-primary {
  background-color: var(--accent);
  border-color: var(--accent);
  font-weight: 800;
  color: #fff;
}

.btn-secondary {
  background-color: #000;
}
