:root {
  --slide-height: 700px;
  --slide-spacing: 1rem;
  --slide-size: 235px;
}

.embla {
  max-width: 48rem;
  margin: auto;
  position: relative;
}

.embla__viewport {
  overflow: hidden;
  position: relative;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  height: 600px;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.coaches__embla__slide__card {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 0;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
}
.embla__controls {
  display: flex;
  position: absolute;
  justify-content: space-between;
  top: 0;
  bottom: 0;
  width: 100%;
}
.embla__buttons {
  padding: 0 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.embla__button {
  display: none;
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--accent);
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;

}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}

.embla__button--prev {
  left: 20px;
}

.embla__button--next {
  right: 35px;
}

.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}
.embla__slide__number {
  backface-visibility: hidden;
}

@media (min-width: 500px) {
  .embla {
    max-width: 48rem;
    margin: auto;
    position: relative;
    --slide-height: 400px;
    --slide-spacing: 0rem;
    --slide-size: 280px;
  }
}

@media (min-width: 600px) {
  .embla {
    max-width: 100%;
    margin: auto;
    position: relative;
    --slide-height: 480px;
    --slide-spacing: 0rem;
    --slide-size: 340px;
  }
}

@media (min-width: 720px) {
  .embla {
    max-width: 100%;
    margin: auto;
    position: relative;
    --slide-height: 550px;
    --slide-spacing: 0rem;
    --slide-size: 400px;
  }
}

@media (min-width: 1024px) {
  .embla {
    max-width: 1320px;
    margin: auto;
    --slide-height: 30rem;
    --slide-spacing: 1rem;
    --slide-size: 26%;
  }

  .embla__button {
    display: inline-flex;
    display: flex;
  }

  .coaches__header {
    flex-direction: column !important;
    align-items: center;
  }
  .coaches__subtitle {
    max-width: 600px;
    font-size: 15px !important;
  }
}

@media (max-width: 767px) {
  .coaches__embla__slide__card {
    height: 400px;
  }
  .embla__container {
    height: 400px;
  }
}