.about__logo {
  height: auto;
  width: 50px;
}

@media (min-width: 1024px) {

  .banner-about__image {
    height: auto !important;
  }

  .banner-about__image img {
    height: auto !important;
    max-height: unset !important;
    object-fit: contain !important;
  }

  .about__title {
    text-align: start !important;
    justify-content: start !important;
    padding: 0 16px !important;
  }

  .about__title p {
    font-size: 60px !important;

  }

  .about__description {
    font-size: 25px !important;
  }

  .about__logo {
    height: 70px;
    width: 70px;
  }
}