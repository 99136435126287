.profile__current-classes {
  font-size: 20px;
}

.profile__current-package {
  font-size: 20px;
}

.mobile__user-image {
  width: 50px !important;
  height: 50px !important;
}

.profile__mobile-menu {
  transition: height 1s ease-in-out;
}

@media (min-width: 1024px) {
  .profile__current-classes {
    font-size: 24px;
  }
  
  .profile__current-package {
    font-size: 24px;
  }
}
